import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

Vue.config.productionTip = false

console.log('main.js: location', location);
if( location.hostname==='118.27.104.24' ){
    Vue.prototype.$fastapi = 'http://'+location.hostname+':9000/';
    Vue.prototype.$base_url = 'http://'+location.hostname+':81/';
    Vue.prototype.$is_dev = true;
    console.log('main.js: is_dev: true');
}else{
    Vue.prototype.$fastapi = 'https://'+location.hostname+':9001/';
    Vue.prototype.$base_url = 'https://'+location.hostname+':81/';
    Vue.prototype.$is_dev = false;
    console.log('main.js: is_dev: false');
}

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
